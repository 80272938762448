import CustomCheckBox from '@/components/customCheckBox';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormattedTextField from '@/components/formattedTextField';
import { useGraphqlResult } from '@/data/query/graphqlProvider';
import currencyFormat from '@/helpers/currencyFormat';
import { useCompany } from '@/hooks/useSetCompanyInAtom';
import TipsSection from '@/pages/dashboard/commerce/payment/tipsSection';
import { surchargeFeeAtom } from '@/pages/settings/cards';
import type { Order } from '@/types/schema';
import { ArrowBackIos as ArrowBackIosIcon } from '@mui/icons-material';
import {
	Collapse,
	Divider,
	ListItem,
	ListItemButton,
	ListItemText,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import BigNumber from 'bignumber.js';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai/index';
import { clamp, isEmpty, round, toLower } from 'lodash-es';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	getCardFeeAmount,
	getCashDiscountPercent,
	getOrderTotalDiscountAndFees,
	getPayingTotalAfterCashDiscount,
} from './helpers';

export default function PaymentAmount( { cancelAmount, confirmTotal, method, cardType }: {
	cancelAmount,
	confirmTotal,
	method: string,
	cardType: string
} ) {
	const { t } = useTranslation();
	const { company } = useCompany();
	const invoice = useGraphqlResult<Order>();
	const queryClient = useQueryClient();
	const [ tipPercent, setTipPercent ] = useState( 0 );
	const [ dollarTip, setDollarTip ] = useState( 0 );
	const [ showTipOptions, setShowTipOptions ] = useState( true );
	const [ paymentAmount, setPaymentAmount ] = useState( 0 );
	const [ selectedTipOption, setSelectedTipOption ] = useState( 4 );
	const [ unPaidScheduledPayments, setUnPaidScheduledPayments ] = useState( [ ...!isEmpty( invoice.scheduledPayments )
		? invoice.scheduledPayments?.filter( ( payment ) => !payment.paid )
			.map( ( payment ) => ( { ...payment, selected: payment.selected || false } ) ) : [] ] );
	const [ select, setSelect ] = useState( 'full' );
	const gateway = invoice.gateway || invoice.companyLocation?.gateway;
	const cloverGateway = gateway?.external === 'CLOVER' ? gateway : null;
	
	const paidScheduledPayments = invoice.scheduledPayments?.filter( ( payment ) => payment.paid );
	const grandTotal = invoice.grandTotal || 0;
	const subTotal = invoice.subTotal || 0;
	const taxTotal = invoice.taxTotal || 0;
	const surchargeFeePercent = useAtomValue( surchargeFeeAtom );
	const surchargePercent = ( method === 'card' || method?.includes( 'saved' ) ) && cardType !== 'debit'
		? surchargeFeePercent || 0
		: 0;
	const cardFee = surchargePercent > 0 ? 0 : getCardFeeAmount( invoice, method, cardType );
	const paidCardFee = invoice.payments?.reduce( ( sum,
		payment ) => sum.plus( payment?.fee || 0 ), new BigNumber( 0 ) ) || 0;
	const cashDiscountPercent = getCashDiscountPercent( invoice, invoice.company, toLower( method ) );
	const totals = getOrderTotalDiscountAndFees( invoice );
	const totalDiscount = new BigNumber( totals.totalDiscount );
	const totalFees = new BigNumber( totals.totalFees );
	const additionalPrices = totalDiscount.plus( totalFees.minus( paidCardFee || 0 ) ).toNumber();
	const remaining = grandTotal - ( invoice.paidTotal || 0 );
	
	const finalRemaining = !cashDiscountPercent
		? remaining
		: getPayingTotalAfterCashDiscount( invoice, cashDiscountPercent ).orderGrandTotal;
	
	const partialPaying = useMemo( () => {
		if ( isEmpty( unPaidScheduledPayments ) ) {
			switch ( select ) {
				case '$':
					return round( clamp( paymentAmount, 0, finalRemaining ), 2 );
				case '%':
					return round( clamp( finalRemaining * paymentAmount / 100, 0, finalRemaining ), 2 );
				case 'full':
					return round( finalRemaining, 2 );
			}
		}
		
	}, [ select, paymentAmount, finalRemaining ] );
	
	const scheduledPaying = useMemo( () => {
		if ( isEmpty( unPaidScheduledPayments ) ) return 0;
		return unPaidScheduledPayments
			.filter( ( payment ) => payment.selected )
			.reduce( ( sum, payment ) => {
				sum += ( subTotal + additionalPrices + taxTotal ) * ( payment.percent || 0 ) / 100;
				return round( sum, 2 );
			}, 0 );
	}, [ unPaidScheduledPayments, finalRemaining, cardFee ] );
	
	const amountToPay = partialPaying || scheduledPaying;
	
	const totalBeforeTax = new BigNumber( subTotal ).plus( additionalPrices );
	
	const paying = Math.min( finalRemaining, amountToPay );
	
	// payingBeforeTax = paying - taxTotal * paying / (subTotal + taxTotal)
	const payingBeforeTax = new BigNumber( paying ).minus( new BigNumber( taxTotal )
		.times( paying )
		.div( totalBeforeTax.plus( taxTotal ) ) );
	
	const cardFeeAmount = payingBeforeTax.times( cardFee ).decimalPlaces( 2 ).toNumber();
	const finalPaying = new BigNumber( paying ).plus( cardFeeAmount ).decimalPlaces( 2 ).toNumber();
	
	console.debug( {
		additionalPrices,
		grandTotal,
		subTotal,
		totalFees           : totalFees.toNumber(),
		subTotalWithDiscount: totalBeforeTax.toNumber(),
		taxTotal,
		partialPaying,
		scheduledPaying,
		amountToPay,
		finalRemaining,
		paying,
		payingBeforeTax     : payingBeforeTax.toNumber(),
		cardFeeAmount,
		finalPaying,
		totalDiscount       : totalDiscount.toNumber(),
	} );
	
	const unPaidScheduledPaymentsContent = useCallback( () => (
		<Stack spacing={1}>
			<Stack direction='column'>
				{unPaidScheduledPayments.map( ( payment ) => {
					const schedulePaymentAmount = round( ( subTotal + additionalPrices + taxTotal ) * ( payment.percent || 0 ) / 100, 2 );
					return (
						<ListItemButton
							key={payment.id}
							disableGutters
							onClick={() => {
								setUnPaidScheduledPayments( unPaidScheduledPayments.map( ( p ) => {
									if ( p.id === payment.id ) {
										return { ...p, selected: !payment.selected };
									}
									return p;
								} ) );
							}}>
							<CustomCheckBox
								checked={payment.selected}
								sx={{ alignSelf: 'start', mt: 0.5 }}
							/>
							<ListItemText
								primary={` ${currencyFormat( schedulePaymentAmount )} (${round( payment.percent, 2 )}% ${cardFee <= 0
									? 'Based off grand total'
									: ''}) ${payment?.reason} `}
								secondary={`${t( 'common:due-on' )} ${format( payment.dueDate, 'PP' )}`}
							/>
						</ListItemButton>
					);
				} )}
			</Stack>
			{amountToPay > finalRemaining && <Typography></Typography>}
			<Divider sx={{ mt: 2 }}/>
		</Stack>
	), [ unPaidScheduledPayments, cardFee, finalRemaining ] );
	
	const fullPartialSelectContent = useCallback( () => (
		<Stack spacing={1}>
			<Stack direction='row' alignItems='center' spacing={1}>
				<Select
					sx={{ width: 100 }}
					value={select}
					size='small'
					onChange={( e ) => setSelect( e.target.value )}>
					<MenuItem value='$'>$</MenuItem>
					<MenuItem value='%'>%</MenuItem>
					<MenuItem value='full'>{t( 'commerce:full' )}</MenuItem>
				</Select>
				<FormattedTextField
					fullWidth
					disabled={select === 'full'}
					value={select === 'full' ? currencyFormat( finalRemaining ) : paymentAmount}
					variant='outlined'
					placeholder={t( 'common:amount' )}
					onChange={( e ) => setPaymentAmount( +e.target.value || 0 )}
					onFocus={( e ) => e.target.select()}
				/>
			</Stack>
			<Divider sx={{ mt: 2 }}/>
		</Stack>
	), [ select, paymentAmount, finalRemaining ] );
	
	const renderAmountContent = () => {
		if ( !isEmpty( unPaidScheduledPayments ) ) {
			return unPaidScheduledPaymentsContent();
		}
		// if invoice metatdata has enablePartialPayment, use that value, otherwise use company metadata
		const enablePartialPayment = invoice.metadata.hasOwnProperty( 'enablePartialPayment' )
			? !invoice.metadata?.enablePartialPayment
			: !invoice.company?.metadata?.enablePartialPayment;
		
		if ( enablePartialPayment ) {
			return fullPartialSelectContent();
		}
		
		return null;
	};
	
	const showTips = invoice.company.metadata?.hideTips ? false : invoice.metadata.hasOwnProperty( 'hideTips' )
		? !invoice.metadata?.hideTips
		: !invoice.company.metadata?.hideTips;
	const tipAmount = dollarTip || tipPercent * paying / 100 || 0;
	const finalPayingWithSurcharge = finalPaying + finalPaying * ( surchargePercent / 100 ) + tipAmount;
	return (
		<Fragment>
			{renderAmountContent()}
			{surchargePercent > 0 ? (
				<ListItem disableGutters>
					<ListItemText primary={`There is a surcharge of ${surchargePercent}%`}/>
					<Typography style={{ fontSize: 18 }}>
						{currencyFormat( finalPaying * ( surchargePercent / 100 ) )}
					</Typography>
				</ListItem>
			) : null}
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:total' )}/>
				<Typography style={{ fontSize: 18 }}>
					{currencyFormat( finalRemaining )}
				</Typography>
			</ListItem>
			<Collapse in={surchargePercent <= 0 && cardFee > 0}>
				<ListItem disableGutters>
					<ListItemText primary='Card Fee'/>
					<Typography style={{ fontSize: 18 }}>
						{currencyFormat( cardFeeAmount )}
					</Typography>
				</ListItem>
			</Collapse>
			<Collapse in={showTips}>
				<ListItem disableGutters>
					<ListItemText primary={t( 'common:tip' )}/>
					<Typography color='success.main' style={{ fontSize: 18 }}>
						{currencyFormat( dollarTip || tipPercent * paying / 100 )}
					</Typography>
				</ListItem>
			</Collapse>
			<ListItem disableGutters>
				<ListItemText primary={t( 'common:paying' )}/>
				<Typography variant='h3'>
					{currencyFormat( finalPayingWithSurcharge )}
				</Typography>
			</ListItem>
			<Collapse in={paying !== finalRemaining}>
				<ListItem disableGutters>
					<ListItemText primary={t( 'common:remaining' )}/>
					<Typography color='warning.main' style={{ fontSize: 18 }}>
						{finalRemaining - paying > 0 ? currencyFormat( finalRemaining - paying ) : '$0.00'}
					</Typography>
				</ListItem>
			</Collapse>
			<TipsSection
				cloverGateway={cloverGateway}
				paying={paying}
				showTips={showTips}
				showTipOptions={showTipOptions}
				setShowTipOptions={setShowTipOptions}
				tipPercent={tipPercent}
				setTipPercent={setTipPercent}
				dollarTip={dollarTip}
				setDollarTip={setDollarTip}
				selectedTipOption={selectedTipOption}
				setSelectedTipOption={setSelectedTipOption}
			/>
			<Stack spacing={2} direction='row' alignItems='center' mt={2}>
				<AsyncLoadingButton
					variant='outlined'
					startIcon={<ArrowBackIosIcon/>}
					onClick={cancelAmount}>
					Back
				</AsyncLoadingButton>
				<AsyncLoadingButton
					variant='contained'
					color='primary'
					disabled={!finalRemaining || !paying}
					onClick={async () => {
						if ( !isEmpty( unPaidScheduledPayments ) ) {
							await axios.post( `/api/orderPublicWrite`, {
								companyId        : company?.id || invoice.company.id,
								id               : invoice.id,
								lineItems        : invoice.lineItems,
								taxPercent       : invoice.taxPercent,
								gateway          : invoice.gateway?.id,
								notes            : invoice.notes,
								client           : invoice.client?.id,
								metadata         : invoice.metadata,
								prices           : invoice.prices,
								number           : invoice.number,
								type             : invoice.type,
								scheduledPayments: [ ...unPaidScheduledPayments, ...paidScheduledPayments ],
							} );
							
							await queryClient.invalidateQueries( [ 'order' ] );
						}
						return confirmTotal( {
							total    : finalPaying,
							cardFee  : !invoice.metadata?.cardFee ? cardFeeAmount : 0,
							tip      : tipPercent * paying / 100,
							dollarTip: dollarTip || tipPercent * paying / 100,
							finalPayingWithSurcharge,
						} );
					}}>
					Continue
				</AsyncLoadingButton>
			</Stack>
		</Fragment>
	);
}
